<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import {required} from "vuelidate/lib/validators";
import {profileMethods, profileComputed, notificationMethods, paymentCardListComputed, paymentCardMethods} from "@/state/helpers";
import Loader from "@/components/Partials/Loader.vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import CardImageSvg from "@/components/Svg/CardImageSvg.vue";
import Cookies from "js-cookie";
import {pixelsMethods} from "@/state/helpers";

export default {
	name: "checkout",
	components: {
		NavBarLeft,
		Loader,
		vSelect,
		CardImageSvg
	},
	data() {
		return {
			selected: "Monthly",
			selectedCard: null,
			selectedPaymentType: "creditCard",
			isDisabledButton: false,
			paymentTypeOptions: [
				{ text: 'Credit Card', value: 'creditCard' },
				{ text: 'Saved Card', value: 'savedCard' },
			],
			submitted: false,
			cardNumber: "",
			cardExpiry: "",
			cvv: "",
		}
	},
	validations: {
		cardNumber: {required},
		cardExpiry: {required},
		cvv: {required},
	},
	computed:{
		...profileComputed,
		...paymentCardListComputed,
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...profileMethods,
		...notificationMethods,
		...paymentCardMethods,
		...pixelsMethods,
		detectCardType() {
			const type = document.getElementById('card-type');
			return type.getAttribute('data-card-brand');
		},
		getSubscriptionType(){
			const getSubs = this.userInfo.subscription_plans.find(item => item.name === this.selected);
			return getSubs.id
		},
		onPaymentCardChange(data){
			this.$store.dispatch('paymentCardList/setSelectedPaymentCard', data)
		},
		checkoutBySavedCard() {
			const checkoutData = {
				pc_id: this.selectedPaymentCard.id,
				subscription_plan_id: this.getSubscriptionType(),
				chat_id: this.$store.state?.activeRoomId
			}
			this.$store.dispatch("profile/updateUserPaymentPlan", checkoutData)
		},
		fillForm() {
			this.sendPixelEvent('fill-form');
		},
		submit() {
			// this.isDisabledButton = true;
			this.sendPixelEvent('click-paybutton');
			this.submitted = true;
			this.$v.$touch();
			let cardExpDateYear = this.cardExpiry.split('/');
			const cardData = {
				card_number: this.cardNumber,
				exp_month: cardExpDateYear[0].trim(),
				exp_year: cardExpDateYear[1],
				cvv: this.cvv,
				type: this.detectCardType(),
				subscription_plan_id: this.getSubscriptionType(),
				chat_id: this.$store.state?.activeRoomId,
				net: Cookies.get('net') === 'undefined' ? '' : Cookies.get('net'),
				aff: Cookies.get('aff') === 'undefined' ? '' : Cookies.get('aff'),
				cid: Cookies.get('cid') === 'undefined' ? '' : Cookies.get('cid'),
				sid: Cookies.get('sid') === 'undefined' ? '' : Cookies.get('sid'),
				ip: Cookies.get('ipAddress'),
			}
			if (this.$v.$invalid) {
				this.sendPixelEvent('fill-payment');
				return this.isDisabledButton = false;
			} else {
				this.$store.dispatch("profile/updateUserPaymentPlan", cardData)
			}
		},
	},
	async mounted() {
		await this.$store.dispatch("profile/getUserInfo");
		await this.$store.dispatch("paymentCardList/getPaymentCardsList");
		if(this.selectedPaymentCard) {
			this.selectedPaymentType = 'savedCard'
		}
		await this.sendPixelEvent('pageview');
	}
}
</script>

<template>
	<div>
		<div class="layout-wrapper d-lg-flex">
			<NavBarLeft/>
			<div class="w-100">
				<div class="px-4 pt-4">
					<h4>Checkout</h4>
					<div class="text-center p-4">
						<h2>Please, select your type of chat using</h2>
					</div>
					<div class="m-auto col-md-8 col-lg-6 col-xl-5">
						<Loader v-if="loading" />
						<div v-else class="card">
							<div class="card-body p-4">
								<b-alert
									:variant="notification.type"
									class="mt-3"
									v-if="notification.message"
									:show="notificationAutoCloseDuration"
									dismissible
								>{{ notification.message }}
								</b-alert>
								<div class="text-left">
									<b-form-group v-slot="{ ariaDescribedby }" class="radio-output">
										<b-form-radio
											v-model="selected"
											:aria-describedby="ariaDescribedby"
											name="some-radios"
											value="Monthly">Unlimited Chats Monthly: $19
										</b-form-radio>
										<b-form-radio
											v-model="selected"
											:aria-describedby="ariaDescribedby"
											name="some-radios"
											value="Daily">One Day for $1
										</b-form-radio>
									</b-form-group>
									<div class="creditcards text-center p-2">
										<svg data-v-fe4f0254="" width="176" height="32" viewBox="0 0 176 32" fill="none"
											 xmlns="http://www.w3.org/2000/svg">
											<rect data-v-fe4f0254="" x="1" y="1" width="46" height="30" rx="5"
												  fill="white" stroke="#D4D4E3" stroke-width="2"></rect>
											<path data-v-fe4f0254=""
												  d="M20.5358 21.4437H17.8218L19.5193 11.2389H22.2332L20.5358 21.4437Z"
												  fill="#0F1459"></path>
											<path data-v-fe4f0254=""
												  d="M30.3733 11.4885C29.838 11.2821 28.9889 11.0541 27.939 11.0541C25.2588 11.0541 23.3714 12.4436 23.3599 14.4302C23.3376 15.8959 24.7112 16.71 25.7385 17.1987C26.7885 17.6981 27.1454 18.024 27.1454 18.4692C27.1348 19.1528 26.297 19.4679 25.5156 19.4679C24.432 19.4679 23.8514 19.3055 22.9691 18.9251L22.6117 18.7621L22.2319 21.0528C22.8685 21.3348 24.0412 21.585 25.2588 21.5959C28.1066 21.5959 29.9605 20.2279 29.9825 18.1109C29.9933 16.9492 29.268 16.0591 27.7044 15.3317C26.7551 14.8648 26.1738 14.5499 26.1738 14.0722C26.1849 13.6379 26.6655 13.193 27.737 13.193C28.6193 13.1713 29.2676 13.3774 29.7587 13.5838L30.0042 13.6921L30.3733 11.4885Z"
												  fill="#0F1459"></path>
											<path data-v-fe4f0254=""
												  d="M33.9823 17.8285C34.2058 17.2422 35.0658 14.9733 35.0658 14.9733C35.0545 14.9951 35.2889 14.3762 35.4229 13.9963L35.6126 14.8756C35.6126 14.8756 36.1265 17.3183 36.2381 17.8285C35.814 17.8285 34.5183 17.8285 33.9823 17.8285ZM37.3324 11.2389H35.2331C34.5857 11.2389 34.0939 11.4233 33.8145 12.0856L29.7832 21.4435H32.631C32.631 21.4435 33.0998 20.184 33.2006 19.9128C33.513 19.9128 36.2832 19.9128 36.6852 19.9128C36.7631 20.271 37.009 21.4435 37.009 21.4435H39.5219L37.3324 11.2389Z"
												  fill="#0F1459"></path>
											<path data-v-fe4f0254=""
												  d="M15.5547 11.2389L12.8968 18.1976L12.6063 16.7863C12.1149 15.1578 10.5738 13.3885 8.854 12.5088L11.2886 21.4329H14.1586L18.4246 11.2389H15.5547Z"
												  fill="#0F1459"></path>
											<path data-v-fe4f0254=""
												  d="M10.4289 11.2389H6.06225L6.01758 11.4451C9.42381 12.2919 11.6797 14.3332 12.6065 16.7867L11.6573 12.0967C11.501 11.4449 11.0207 11.2604 10.4289 11.2389Z"
												  fill="#FAA61A"></path>
											<mask data-v-fe4f0254="" id="mask0_651_8263" maskUnits="userSpaceOnUse"
												  x="64" y="0" width="48" height="32" style="mask-type: alpha;">
												<rect data-v-fe4f0254="" x="64" width="48" height="32"
													  fill="#016FD0"></rect>
											</mask>
											<g data-v-fe4f0254="" mask="url(#mask0_651_8263)">
												<rect data-v-fe4f0254="" x="65" y="1" width="46" height="30" rx="5"
													  stroke="#D4D4E3" stroke-width="2"></rect>
												<path data-v-fe4f0254=""
													  d="M78.165 21.6V16.7522H83.4446L84.011 17.4701L84.5962 16.7522H103.76V21.2657C103.76 21.2657 103.259 21.5951 102.679 21.6H92.0677L91.4291 20.8358V21.6H89.3363V20.2955C89.3363 20.2955 89.0504 20.4776 88.4324 20.4776H87.7201V21.6H84.5514L83.9858 20.8667L83.4115 21.6H78.165Z"
													  fill="#EEEFFB"></path>
												<path data-v-fe4f0254=""
													  d="M72 13.0985L73.1906 10.4H75.2495L75.9252 11.9116V10.4H78.4846L78.8869 11.4926L79.2768 10.4H90.7662V10.9493C90.7662 10.9493 91.3701 10.4 92.3628 10.4L96.0906 10.4127L96.7546 11.9045V10.4H98.8965L99.486 11.257V10.4H101.648V15.2478H99.486L98.9211 14.3881V15.2478H95.7742L95.4577 14.4836H94.6117L94.3004 15.2478H92.1663C91.3121 15.2478 90.7662 14.7098 90.7662 14.7098V15.2478H87.5484L86.9098 14.4836V15.2478H74.9445L74.6282 14.4836H73.7849L73.4709 15.2478H72V13.0985Z"
													  fill="#EEEFFB"></path>
												<path data-v-fe4f0254=""
													  d="M73.6112 10.9974L72.0054 14.6273H73.0508L73.3471 13.9004H75.0696L75.3644 14.6273H76.4329L74.8286 10.9974H73.6112ZM74.2053 11.8422L74.7303 13.1124H73.6787L74.2053 11.8422Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M76.5435 14.6266V10.9967L78.0291 11.0021L78.8932 13.3424L79.7367 10.9967H81.2104V14.6266H80.277V11.9519L79.2876 14.6266H78.469L77.4769 11.9519V14.6266H76.5435Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M81.8496 14.6266V10.9967H84.8955V11.8087H82.7928V12.4296H84.8463V13.1938H82.7928V13.8385H84.8955V14.6266H81.8496Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M85.4351 10.9974V14.6273H86.3685V13.3378H86.7615L87.8806 14.6273H89.0213L87.7931 13.29C88.2972 13.2487 88.8171 12.8281 88.8171 12.1751C88.8171 11.4112 88.2004 10.9974 87.5122 10.9974H85.4351ZM86.3685 11.8094H87.4354C87.6914 11.8094 87.8776 12.004 87.8776 12.1915C87.8776 12.4326 87.6363 12.5736 87.4492 12.5736H86.3685V11.8094Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M90.1528 14.6266H89.1997V10.9967H90.1528V14.6266Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M92.4123 14.6266H92.2066C91.2113 14.6266 90.6069 13.8642 90.6069 12.8266C90.6069 11.7633 91.2045 10.9967 92.4615 10.9967H93.4931V11.8564H92.4237C91.9135 11.8564 91.5526 12.2436 91.5526 12.8355C91.5526 13.5385 91.9653 13.8338 92.5597 13.8338H92.8053L92.4123 14.6266Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M94.4437 10.9974L92.8379 14.6273H93.8834L94.1796 13.9004H95.9021L96.1969 14.6273H97.2654L95.6611 10.9974H94.4437ZM95.0378 11.8422L95.5629 13.1124H94.5112L95.0378 11.8422Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M97.3735 14.6266V10.9967H98.5602L100.075 13.2773V10.9967H101.009V14.6266H99.8606L98.3069 12.2863V14.6266H97.3735Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M78.8037 20.979V17.3491H81.8496V18.1611H79.7469V18.782H81.8004V19.5462H79.7469V20.191H81.8496V20.979H78.8037Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M93.728 20.979V17.3491H96.7739V18.1611H94.6713V18.782H96.7149V19.5462H94.6713V20.191H96.7739V20.979H93.728Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M81.9684 20.979L83.4514 19.1864L81.9331 17.3491H83.1091L84.0133 18.4849L84.9206 17.3491H86.0505L84.5521 19.1641L86.0379 20.979H84.8621L83.9841 19.8611L83.1275 20.979H81.9684Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M86.1475 17.3497V20.9796H87.1054V19.8334H88.088C88.9193 19.8334 89.5495 19.4045 89.5495 18.5706C89.5495 17.8798 89.0552 17.3497 88.2092 17.3497H86.1475ZM87.1054 18.1706H88.1402C88.4087 18.1706 88.6007 18.3307 88.6007 18.5886C88.6007 18.8308 88.4097 19.0065 88.1371 19.0065H87.1054V18.1706Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M89.9556 17.3491V20.979H90.889V19.6895H91.282L92.4011 20.979H93.5418L92.3136 19.6417C92.8177 19.6003 93.3376 19.1797 93.3376 18.5268C93.3376 17.7629 92.7209 17.3491 92.0327 17.3491H89.9556ZM90.889 18.1611H91.9559C92.2119 18.1611 92.3981 18.3557 92.3981 18.5432C92.3981 18.7843 92.1568 18.9253 91.9698 18.9253H90.889V18.1611Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M97.2069 20.979V20.191H99.0749C99.3513 20.191 99.471 20.0457 99.471 19.8865C99.471 19.7339 99.3517 19.5796 99.0749 19.5796H98.2308C97.497 19.5796 97.0884 19.145 97.0884 18.4924C97.0884 17.9104 97.4626 17.3491 98.553 17.3491H100.371L99.9776 18.1658H98.4056C98.1051 18.1658 98.0126 18.3192 98.0126 18.4656C98.0126 18.616 98.1269 18.782 98.3565 18.782H99.2407C100.059 18.782 100.414 19.2331 100.414 19.8238C100.414 20.4589 100.018 20.979 99.1962 20.979H97.2069Z"
													  fill="#1C1C20"></path>
												<path data-v-fe4f0254=""
													  d="M100.632 20.979V20.191H102.5C102.776 20.191 102.896 20.0457 102.896 19.8865C102.896 19.7339 102.777 19.5796 102.5 19.5796H101.656C100.922 19.5796 100.513 19.145 100.513 18.4924C100.513 17.9104 100.887 17.3491 101.978 17.3491H103.758L103.402 18.1658H101.83C101.53 18.1658 101.437 18.3192 101.437 18.4656C101.437 18.616 101.552 18.782 101.781 18.782H102.666C103.484 18.782 103.838 19.2331 103.838 19.8238C103.838 20.4589 103.443 20.979 102.621 20.979H100.632Z"
													  fill="#1C1C20"></path>
											</g>
											<rect data-v-fe4f0254="" x="129" y="1" width="46" height="30" rx="5"
												  stroke="#D4D4E3" stroke-width="2"></rect>
											<path data-v-fe4f0254="" fill-rule="evenodd" clip-rule="evenodd"
												  d="M152.343 22.8759C150.708 24.2469 148.589 25.0746 146.272 25.0746C141.104 25.0746 136.914 20.9538 136.914 15.8706C136.914 10.7874 141.104 6.66663 146.272 6.66663C148.589 6.66663 150.708 7.49429 152.343 8.86533C153.977 7.49429 156.097 6.66663 158.413 6.66663C163.581 6.66663 167.771 10.7874 167.771 15.8706C167.771 20.9538 163.581 25.0746 158.413 25.0746C156.097 25.0746 153.977 24.2469 152.343 22.8759Z"
												  fill="#ED0006"></path>
											<path data-v-fe4f0254="" fill-rule="evenodd" clip-rule="evenodd"
												  d="M152.343 22.8759C154.355 21.1877 155.631 18.6757 155.631 15.8706C155.631 13.0655 154.355 10.5535 152.343 8.86533C153.977 7.49429 156.097 6.66663 158.413 6.66663C163.581 6.66663 167.771 10.7874 167.771 15.8706C167.771 20.9538 163.581 25.0746 158.413 25.0746C156.097 25.0746 153.977 24.2469 152.343 22.8759Z"
												  fill="#F9A000"></path>
											<path data-v-fe4f0254="" fill-rule="evenodd" clip-rule="evenodd"
												  d="M152.343 22.8758C150.331 21.1877 149.055 18.6757 149.055 15.8706C149.055 13.0655 150.331 10.5535 152.343 8.86536C154.355 10.5535 155.631 13.0655 155.631 15.8706C155.631 18.6757 154.355 21.1877 152.343 22.8758Z"
												  fill="#FF5E00"></path>
										</svg>
									</div>
									<b-form-group v-if="paymentCardList.length" class="radio-output mb-4">
										<b-form-radio-group
											id="radio-group-1"
											v-model="selectedPaymentType"
											:options="paymentTypeOptions"
											name="radio-options"
										></b-form-radio-group>
									</b-form-group>
									<div v-if="selectedPaymentType === 'savedCard' && paymentCardList.length" class="mt-2 mb-4">
										<v-select
											@input="onPaymentCardChange"
											:options="paymentCardList"
											:value="selectedPaymentCard"
											label="type"
											:searchable="false"
											class="mb-4"
										>
											<template #selected-option="{ type, number }">
												<div class="d-flex align-items-center">
													<CardImageSvg :type="type"/>
													<p class="pl-2" style="margin: 0;">*{{number}}</p>
												</div>
											</template>
											<template #option="{ type, number }">
												<div class="d-flex align-items-center">
													<CardImageSvg :type="type"/>
													<p class="pl-2" style="margin: 0;">*{{number}}</p>
												</div>
											</template>
										</v-select>
										<button
											class="btn btn-primary btn-block"
											@click="checkoutBySavedCard"
										>Submit
										</button>
									</div>
									<form v-else @submit.prevent="submit">
										<div class="form-group">
											<label>{{ $t('checkout.form.card') }}</label>
											<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
												<div class="input-group-prepend">
													<span class="input-group-text border-light text-muted">
													  <i class="ri-bank-card-fill"></i>
													</span>
												</div>
												<input
													v-model="cardNumber"
													id="card-type"
													class="form-control bg-soft-light border-light"
													:class="{ 'is-invalid': submitted && $v.cardNumber.$error }"
													:placeholder="$t('checkout.form.card')"
													v-cardformat:formatCardNumber
													@blur="fillForm"
												/>
												<div v-if="submitted && $v.cardNumber.$error" class="invalid-feedback">
													<span
														v-if="!$v.cardNumber.required"
													>Error. </span>
													<span v-if="!$v.cardNumber.cardNumber">Please provide valid card number</span>
												</div>
											</div>
										</div>
										<div class="form-group">
											<label>{{ $t('checkout.form.expiryDate') }}</label>
											<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
												<input
													v-model="cardExpiry"
													class="form-control bg-soft-light border-light"
													:class="{ 'is-invalid': submitted && $v.cardExpiry.$error }"
													:placeholder="$t('checkout.form.expiryDate')"
													v-cardformat:formatCardExpiry
												/>
												<div v-if="submitted && $v.cardExpiry.$error" class="invalid-feedback">
													<span
														v-if="!$v.cardExpiry.required"
													>Error. </span>
													<span v-if="!$v.cardExpiry.cardExpiry">Please provide valid card exp. date</span>
												</div>
											</div>
										</div>
										<div class="form-group">
											<label>{{ $t('checkout.form.cvv') }}</label>
											<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
												<input
													v-model="cvv"
													class="form-control bg-soft-light border-light"
													:class="{ 'is-invalid': submitted && $v.cvv.$error }"
													:placeholder="$t('checkout.form.cvv')"
													v-cardformat:formatCardCVC
												/>
												<div v-if="submitted && $v.cvv.$error" class="invalid-feedback">
													<span
														v-if="!$v.cvv.required"
													>Error. </span>
													<span v-if="!$v.cvv.cvv">Please provide valid cvv</span>
												</div>
											</div>
										</div>
										<div>
											<button
												class="btn btn-primary btn-block"
												type="submit"
												:disabled="isDisabledButton"
											>Submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>